import * as React from "react";

type EnvironmentContextType = { isRunningInApplicationFrame: boolean };

export const EnvironmentContext = React.createContext<EnvironmentContextType>({
    isRunningInApplicationFrame: true,
});

type EnvironmentProviderProps = { isRunningInApplicationFrame: boolean; children: React.ReactNode };

export function EnvironmentProvider({ isRunningInApplicationFrame, children }: EnvironmentProviderProps) {
    return (
        <EnvironmentContext.Provider value={{ isRunningInApplicationFrame }}>{children}</EnvironmentContext.Provider>
    );
}
