import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Button, ButtonGroup, Dialog, DialogContent, DialogFooter } from "@emisgroup/ui-kit-react";
import PreferencesContext from "../../context/preferencesContext";
import PrivacyDetails from "./PrivacyDetails";

import "./Style.scss";

const PrivacyPanel = () => {
    const { t } = useTranslation();
    const { currentCookieConsent, setCookieConsent } = React.useContext(PreferencesContext);
    const noInitialPreference = typeof currentCookieConsent === "undefined";
    const [showDialog, setShowDialog] = React.useState(noInitialPreference);
    const [showDetails, setShowDetails] = React.useState(false);

    const setCookiePreference = (newPreference: boolean) => {
        setCookieConsent(newPreference);
        setShowDetails(false);
        setShowDialog(false);
    };
    const onAccept = React.useCallback(() => setCookiePreference(true), [setCookiePreference]);
    const onReject = React.useCallback(() => setCookiePreference(false), [setCookiePreference]);
    const onBack = React.useCallback(() => setShowDetails(false), [setShowDetails]);
    const onMoreOptions = React.useCallback(() => setShowDetails(true), [setShowDetails]);
    const onClose = React.useCallback(() => {
        /* Close is hidden */
    }, []);
    const onEditPreference = React.useCallback(() => setShowDialog(true), [setShowDialog]);

    const PrivacySummary = () => (
        <>
            {" "}
            <div>{t("Privacy.CookiePreferences")}</div>
            <br />
            <div>{t("Privacy.CookiePreferencesNote")}</div>
            <br />
            <div>{t("Privacy.CookiePreferencesAgree")}</div>
        </>
    );

    return (
        <div className="privacy-panel">
            <Button iconName="configuration" variant="borderless" onClick={onEditPreference}>
                {t("Privacy.Button")}
            </Button>
            <Dialog open={showDialog} onClose={onClose} title={t("Privacy.CookieTitle")} disableDismiss={true}>
                <DialogContent>{showDetails ? <PrivacyDetails /> : <PrivacySummary />}</DialogContent>
                <DialogFooter>
                    <ButtonGroup className="privacy-button-group">
                        <div className="privacy-button-group-left">
                            {showDetails && (
                                <Button
                                    className="privacy-back-button"
                                    type="button"
                                    variant="borderless"
                                    iconName="arrow-left"
                                    onClick={onBack}
                                >
                                    {t("Privacy.Back")}
                                </Button>
                            )}
                        </div>
                        <div className="privacy-button-group-right">
                            <Button type="button" variant="filled" onClick={onAccept}>
                                {t(showDetails ? "Privacy.CookiesAccept" : "Privacy.Agree")}
                            </Button>
                            <Button
                                // renders as hidden instead of not rendering at all because otherwise the alternate button appears as if selected
                                className={showDetails ? "" : "privacy-hidden"}
                                type="button"
                                variant="borderless"
                                onClick={onReject}
                            >
                                {t("Privacy.CookiesReject")}
                            </Button>
                            <Button
                                className={showDetails ? "privacy-hidden" : ""}
                                type="button"
                                variant="borderless"
                                onClick={onMoreOptions}
                            >
                                {t("Privacy.MoreOptions")}
                            </Button>
                        </div>
                    </ButtonGroup>
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default PrivacyPanel;
