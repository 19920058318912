import * as React from "react";
import { useLocation } from "react-router";
import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-kit-react";
import { useSessionContext } from "@emisgroup/application-session-react";
import { SendMessageForm, SendResult } from "@emisgroup/clint-comms-ui";
import { openApiDataSource, ContactDetailsDataSource } from "@emisgroup/clint-comms-contact-details";
import { RecipientIdentifier, SendMethod, SendRequest } from "@emisgroup/clint-comms-types";
import {
    getPatientForenames,
    getPatientIdentifier,
    getPatientPreferredName,
    getPatientSurname,
    getPatientTitle,
} from "../patient";
import { useNotifications } from "../../context/notificationProvider";
import { TelemetryContext } from "../../telemetry";
import useConsultation, { IssueMethod } from "./useConsultation";
import { AudienceType, Feature } from "../types";
import TokenContext from "../../context/tokenContext";
import FeatureContext from "../../context/featuresContext";

type SendToPatientButtonProps = {
    alternativeUrl?: string;
    headline?: string;
    audienceType?: AudienceType;
};

type MessageDataValue = {
    value: string;
    displayAsLink?: boolean;
    linkText?: string;
};

type MessageData = {
    ArticleLink: MessageDataValue;
    User: MessageDataValue;
    Organisation: MessageDataValue;
    PatientName?: MessageDataValue;
    PatientFormalName?: MessageDataValue;
};

function SendToPatientButton({ alternativeUrl, headline, audienceType }: SendToPatientButtonProps) {
    const { t } = useTranslation();
    const [showingForm, setShowingForm] = React.useState(false);
    const sessionContext = useSessionContext();
    const { trackException, trackSend } = React.useContext(TelemetryContext);
    const { token, refreshToken } = React.useContext(TokenContext);
    const { pathname: path } = useLocation();

    React.useEffect(() => {
        refreshToken();
    }, [refreshToken]);

    const openApi: ContactDetailsDataSource = openApiDataSource(
        process.env.APP_OPEN_API_URL ?? "",
        sessionContext.accessToken.value ?? "",
    );
    const recipientIdentifier: RecipientIdentifier = {
        recipientType: "patient",
        identifier: getPatientIdentifier(sessionContext),
    };
    const populationApi = React.useCallback(
        async (requests: SendRequest[], recipient?: RecipientIdentifier) => {
            try {
                return await openApi.populateContactDetails(recipient?.identifier ?? "", requests);
            } catch (e) {
                e.message = `${e.message} (contact details)`;
                trackException({
                    exception: e,
                    properties: { recipentType: recipient?.recipientType, recipientIdentifier: recipient?.identifier },
                });
                return requests;
            }
        },
        [openApi, trackException],
    );
    const orgName = sessionContext.userClaims?.orgName;
    const emailFromName = orgName ? t("Email.FromName", { orgName }) : t("Email.FromNameDefault");
    const initialRequests = React.useMemo(
        (): SendRequest[] => [
            {
                recipientIdentifier,
                method: SendMethod.Email,
                methodDetails: {
                    fromAddress: process.env.APP_EMAIL_FROM,
                    fromName: emailFromName,
                    subject: t("Email.Subject"),
                },
                message: t("Email.MessageTemplate", { skipInterpolation: true }),
                consent: true,
            },
        ],
        [t],
    );

    const hasContent = (value: any): boolean => value?.length > 0;

    const messageData = React.useMemo((): MessageData => {
        const patientPreferredName = getPatientPreferredName(sessionContext);
        const patientForenames = getPatientForenames(sessionContext);
        const patientFirstName = hasContent(patientForenames) ? patientForenames[0] : "";
        const patientSurname = getPatientSurname(sessionContext);
        const patientTitle = getPatientTitle(sessionContext);
        const patientName = hasContent(patientPreferredName) ? patientPreferredName : patientFirstName;
        const patientFormalName = [patientTitle, patientFirstName, patientSurname].filter(hasContent).join(" ");
        const userName = [sessionContext.userClaims?.givenName, sessionContext.userClaims?.familyName]
            .filter(Boolean)
            .join(" ");

        return {
            ArticleLink: { value: alternativeUrl || "", displayAsLink: true, linkText: headline },
            User: { value: userName },
            Organisation: { value: orgName || "" },
            PatientName: hasContent(patientName) ? { value: patientName } : undefined,
            PatientFormalName: hasContent(patientFormalName) ? { value: patientFormalName } : undefined,
        };
    }, [headline, alternativeUrl, sessionContext]);
    const { isFeatureEnabled } = React.useContext(FeatureContext);
    const { showNotification, setLoading, clearLoading } = useNotifications();
    const postConsultation = useConsultation({
        name: headline || "",
        url: alternativeUrl || "",
        issueMethod: IssueMethod.Email,
        audienceType,
        onCompleted: (success: boolean) => {
            clearLoading();
            showNotification(t("Email.Sent"), "confirmation");
            if (success) {
                showNotification(t("Reader.SavedToRecord"), "confirmation");
            } else {
                showNotification(t("Reader.NotSavedToRecord"), "error");
            }
        },
    });
    const handleSending = React.useCallback(() => setLoading(t("Email.InProgress")), [setLoading]);
    const handleSend = React.useCallback(
        (_: SendRequest, result: SendResult) => {
            if (result.success) {
                trackSend({ path });
                setLoading(t("Consultation.InProgress"));
                postConsultation(new Date());
            } else {
                clearLoading();
                showNotification(t("Email.NotSent"), "error");
                trackException({ exception: new Error(`${result.error} (send email)` || "Unknown error") });
            }
            setShowingForm(false);
        },
        [path, trackSend, setLoading, postConsultation, setShowingForm, clearLoading, showNotification],
    );
    const showSendToPatient = React.useCallback(() => setShowingForm(true), [setShowingForm]);
    const hideSendToPatient = React.useCallback(() => setShowingForm(false), [setShowingForm]);
    return alternativeUrl && isFeatureEnabled(Feature.SendToPatient) ? (
        <>
            <Button
                data-testid="send-to-patient"
                variant="borderless"
                disabled={!recipientIdentifier.identifier}
                onClick={showSendToPatient}
            >
                {t("Reader.SendToPatient")}
            </Button>
            {showingForm && (
                <SendMessageForm
                    onSending={handleSending}
                    onSend={handleSend}
                    onCancel={hideSendToPatient}
                    populationApi={populationApi}
                    recipientIdentifier={recipientIdentifier}
                    initialRequests={initialRequests}
                    messageData={messageData}
                    clintCommsUrl={process.env.APP_CLINT_COMMS_URL}
                    clintCommsToken={token}
                />
            )}
        </>
    ) : (
        <></>
    );
}

export default SendToPatientButton;
