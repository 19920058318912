import * as React from "react";
import { Notification } from "@emisgroup/ui-kit-react";

import "./Style.scss";

type TimedNotificationProps = {
    type: "confirmation" | "info" | "error";
    text: string;
    milliseconds: number;
    onTimeout: () => void;
};

const TimedNotification = ({ type, text, milliseconds, onTimeout }: TimedNotificationProps) => {
    React.useEffect(() => {
        const notificationTimer = setTimeout(() => {
            onTimeout();
        }, milliseconds);

        return () => {
            clearTimeout(notificationTimer);
        };
    }, []);

    return <Notification notificationType={type} content={text} onClose={onTimeout} />;
};

export default TimedNotification;
