import { useEffect } from "react";
import { useSessionContext } from "@emisgroup/application-session-react";
import { SessionItems } from "@emisgroup/application-session-management";
import { PATIENT_SESSION_ITEM } from "./patient";

const EXTENSION_REGISTRATION_EVENT = "__PatientAccessConnectInitialised__";
const EXTENSION_READY_EVENT = "__PatientAccessConnectReady__";

export default function usePacExtension() {
    const sessionContext = useSessionContext();

    useEffect(() => {
        const handleExtensionReady = () => {
            const patientContext = sessionContext.GetItem(PATIENT_SESSION_ITEM);
            document.dispatchEvent(
                new CustomEvent(EXTENSION_REGISTRATION_EVENT, {
                    detail: {
                        patient: patientContext || null,
                        openApiHub: process.env.APP_OPEN_API_URL || null,
                    },
                }),
            );
        };
        document.addEventListener(EXTENSION_READY_EVENT, handleExtensionReady);
        return () => {
            document.removeEventListener(EXTENSION_READY_EVENT, handleExtensionReady);
        };
    }, []);

    useEffect(() => {
        if (sessionContext.GetItem(SessionItems.SubjectERN)) {
            sessionContext.RemoveItem(SessionItems.SubjectERN);
        }

        const patientContext = sessionContext.GetItem(PATIENT_SESSION_ITEM);
        const appFrameModuleZone = document.getElementById("moduleZone");
        if (patientContext && appFrameModuleZone) {
            appFrameModuleZone.className = "withPatientBanner";
        }
    }, [sessionContext]);
}
