import React, { ReactNode } from "react";
import { IArticleIdentifier } from "../components/types";
import ArticleContext from "./articleContext";

type ArticleProviderProps = {
    children: ReactNode;
    initialArticle?: IArticleIdentifier;
};

const ArticleProvider = ({ children, initialArticle }: ArticleProviderProps): JSX.Element => {
    return (
        <ArticleContext.Provider
            value={{
                selectedArticle: initialArticle,
            }}
        >
            {children}
        </ArticleContext.Provider>
    );
};

export default ArticleProvider;
