import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-kit-react";
import React from "react";
import { Article, ARTICLE_GROUP_NAMES, QueryResults } from "./types";
import { AudienceType } from "../types";
import useSearchMetrics from "./useSearchMetrics";
import useArticleQuery from "./useArticleQuery";
import TimedSpinner from "../Progress/TimedSpinner";
import Error from "../Progress/Error";
import ScrollingResults from "./scrollingResults";
import SearchResult from "./SearchResult";

export type SearchResultsProps = {
    term: string;
    audienceType: AudienceType;
    showingMore?: boolean;
    loadingDelayMs?: number;
    seeMoreResultsClick?: () => void;
};

const SHOW_SOME_RESULT_COUNT = 6;

type SeeMoreButtonProps = {
    query: QueryResults;
    onClick?: () => void;
};
const SeeMoreButton = ({ query, onClick }: SeeMoreButtonProps) => {
    const { t } = useTranslation();

    return query.totalResultCount > SHOW_SOME_RESULT_COUNT ? (
        <div className="kc-search__group-more">
            <Button variant="borderless" onClick={onClick}>
                {t("Search.SeeMoreResults", { count: query.totalResultCount })}
            </Button>
        </div>
    ) : null;
};

const ErrorDisplay = (): JSX.Element => (
    <Error className="kc-search__info" title="Search.ErrorTitle" detail="Search.ErrorDetail" />
);

type ResultsViewProps = {
    query: QueryResults;
    showMore?: boolean;
    loadingDelayMs?: number;
};

const createError = () => <ErrorDisplay />;

const ResultsView = ({ query, loadingDelayMs, showMore }: ResultsViewProps) => {
    const { t } = useTranslation();

    if (query.error) return <ErrorDisplay />;

    if (query.loading)
        return (
            <TimedSpinner
                loading={query.loading}
                delayMs={loadingDelayMs}
                className="kc-search__progress"
                text={t("Search.Loading")}
                error={createError}
            />
        );

    if (query.results.length === 0)
        return (
            <div className="kc-search__info">
                <div className="kc-search__info-text">{t("Search.NoResults")}</div>
            </div>
        );

    return showMore === true ? (
        <ScrollingResults
            items={query.results}
            isNextPageLoading={query.loading}
            hasNextPage={query.hasNextPage}
            loadNextPage={query.nextPage}
        />
    ) : (
        <>
            {query.results.slice(0, SHOW_SOME_RESULT_COUNT).map((a: Article) => (
                <SearchResult key={a.id} result={a} />
            ))}
        </>
    );
};

const SearchResults = (props: SearchResultsProps): JSX.Element => {
    const { t } = useTranslation();
    const query = useArticleQuery(props.term, props.audienceType);

    useSearchMetrics({ ...props, query });

    return (
        <div className="kc-search__group">
            <div className="kc-search__group-name">{t(ARTICLE_GROUP_NAMES[props.audienceType])}</div>
            <ResultsView query={query} loadingDelayMs={props.loadingDelayMs} showMore={props.showingMore} />
            {!props.showingMore && <SeeMoreButton query={query} onClick={props.seeMoreResultsClick} />}
        </div>
    );
};

export default SearchResults;
