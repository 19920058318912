import { IdpType, ISessionContext } from "@emisgroup/application-session-management";
import React, { ReactNode } from "react";
import jwtDecode from "jwt-decode";
import useStateRef from "react-usestateref";
import { ExceptionTelemetry, TelemetryContext } from "../telemetry";
import TokenContext from "./tokenContext";

const wait = (milliseconds: number): Promise<void> =>
    new Promise(resolve => (milliseconds ? setTimeout(resolve, milliseconds) : resolve()));

const MAX_ATTEMPTS = 3;
const RETRY_MILLISECONDS = 1000;

type Token = { exp: number };

const getTokenExpiry = (tokenKey: string): number => {
    const { exp } = jwtDecode<Token>(tokenKey);
    return exp;
};

const isExpired = (expiry: number | null) => {
    if (!expiry) {
        return true;
    }
    const currentTime = new Date().getTime() / 1000;
    return expiry < currentTime;
};

const requestToken = async (
    trackException: (event: ExceptionTelemetry) => void,
    sessionContext?: ISessionContext,
    retryTimeout?: number,
    attempts = 0,
): Promise<string> => {
    if (sessionContext?.accessToken.idp === IdpType.Patient) {
        return sessionContext?.accessToken?.value ?? "";
    }

    const details = {
        client_id: process.env.APP_PP_AUTH_CLIENT_ID,
        grant_type: "exchange",
        scope: "ppq:authenticated clint_comms_api:send",
        token: sessionContext?.accessToken?.value,
    };

    const formBody: string[] = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in details) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    }

    let response;
    try {
        response = await fetch(process.env.APP_PP_AUTH_TOKEN_URL ?? "", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: formBody.join("&"),
        });
    } catch (exception) {
        exception.message = `${exception.message} (get token)`;
        trackException({
            exception,
            properties: {
                url: process.env.APP_PP_AUTH_TOKEN_URL,
                client_id: process.env.APP_PP_AUTH_CLIENT_ID,
                token: sessionContext?.accessToken?.value,
            },
        });
        return "";
    }

    switch (true) {
        case !response.ok && attempts < MAX_ATTEMPTS: {
            const milliseconds = typeof retryTimeout === "undefined" ? RETRY_MILLISECONDS : retryTimeout;
            await wait(milliseconds);
            return requestToken(trackException, sessionContext, retryTimeout, attempts + 1);
        }
        case !response.ok:
            const responseText = await response.text();
            trackException({
                exception: new Error(
                    `Failed to get token: status:${response.status}, statusText:${response.statusText}, responseText:${responseText}`,
                ),
                properties: {
                    token: sessionContext?.accessToken?.value || "",
                },
            });
            return "";
        default: {
            const result = await response.json();
            return result.access_token;
        }
    }
};

type TokenProviderProps = {
    children: ReactNode;
    sessionContext?: ISessionContext;
    retryTimeout?: number;
};

const TokenProvider = ({ sessionContext, retryTimeout, children }: TokenProviderProps): JSX.Element => {
    const { trackException } = React.useContext(TelemetryContext);
    const [token, setToken, tokenRef] = useStateRef<string>("");
    const [, setTokenExpiry, tokenExpiryRef] = useStateRef<number | null>(null);
    const isTokenValid = (token ?? "").length !== 0;

    const refreshToken = React.useCallback(async (): Promise<void> => {
        const currentToken = tokenRef.current;

        if (!currentToken || isExpired(tokenExpiryRef.current)) {
            const requestedToken = await requestToken(trackException, sessionContext, retryTimeout);
            setToken(requestedToken);
            if (requestedToken !== "") setTokenExpiry(getTokenExpiry(requestedToken));
        }
    }, []);

    React.useEffect(() => {
        setToken("");
        if (sessionContext?.accessToken?.value) {
            refreshToken();
        }
    }, [sessionContext?.accessToken?.value]);

    return (
        <TokenContext.Provider
            value={{
                token,
                isTokenValid,
                refreshToken,
            }}
        >
            {children}
        </TokenContext.Provider>
    );
};

export default TokenProvider;
