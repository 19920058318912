const PREVIOUS_SESSION_PATIENT_KEY = "previous-session-patient-guid";
const ROOT_PATH = "/";

export function handlePatientChanged(
    currentPatientContext: string | null,
    currentPath: string,
    currentSearchParams: string,
    storage: {
        getItem(key: string): string | null;
        setItem(key: string, value: string): void;
        removeItem(key: string): void;
    },
    redirect: (to: string, options?: { replace?: boolean }) => void,
) {
    const patientInitialised = storage.getItem("patient-initialised");
    const lastLoadedPatient = storage.getItem(PREVIOUS_SESSION_PATIENT_KEY);
    const currentPatient = currentPatientContext ? JSON.parse(currentPatientContext) : null;
    const patientHasChanged = patientInitialised && currentPatient && lastLoadedPatient !== currentPatient.PatientGuid;

    if (patientHasChanged && (currentPath !== ROOT_PATH || Boolean(currentSearchParams))) {
        redirect(ROOT_PATH, { replace: true });
    }

    if (currentPatient) {
        storage.setItem(PREVIOUS_SESSION_PATIENT_KEY, currentPatient.PatientGuid);
        storage.setItem("patient-initialised", "true");
    } else {
        storage.removeItem(PREVIOUS_SESSION_PATIENT_KEY);
    }
}
