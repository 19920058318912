import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-kit-react";
import React from "react";
import ArticleContext from "../../context/articleContext";
import TokenContext from "../../context/tokenContext";
import { AudienceType, IArticleIdentifier, Feature } from "../types";
import withNavigation, { IWithNavigation } from "../withNavigation";
import TargetedBannerAd from "./TargetedBannerAd";
import ArticleContent from "./ArticleContent";
import CopyLinkButton from "./CopyLinkButton";
import PrintArticleButton from "./PrintArticleButton";
import Logo from "./logo-patient.svg";
import LogoMark from "./logomark-patient.svg";
import useArticleContentQuery from "./useArticleContentQuery";
import formatExternalLink, { LinkOptions } from "./formatExternalLink";
import SendToPatientButton from "./sendToPatientButton";
import { TelemetryContext } from "../../telemetry";
import FeatureContext from "../../context/featuresContext";

import "./Style.scss";

type SelectedArticleReaderProps = { selectedArticle: IArticleIdentifier } & ArticleReaderProps;

type ArticleReaderProps = IWithNavigation;

type ArticleToolbarProps = {
    onBackClick: () => void;
    onCloseClick: () => void;
    canSelectPrevious: boolean;
    alternativeUrl?: string;
    headline?: string;
    audienceType?: AudienceType;
};

type ArticleBrandProps = {
    alternativeUrl?: string;
};

type ArticleProvenanceProps = {
    linkOptions?: LinkOptions;
};

export const ArticleToolbar = (props: ArticleToolbarProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="kc-reader__toolbar">
            <Button
                disabled={!props.canSelectPrevious}
                onClick={props.onBackClick}
                variant="borderless"
                iconName="arrow-left"
                data-testid="back-button"
            >
                {t("Reader.Back")}
            </Button>
            <SendToPatientButton
                alternativeUrl={props.alternativeUrl}
                headline={props.headline}
                audienceType={props.audienceType}
            />
            <PrintArticleButton
                alternativeUrl={props.alternativeUrl}
                headline={props.headline}
                audienceType={props.audienceType}
            />
            <CopyLinkButton
                alternativeUrl={props.alternativeUrl}
                headline={props.headline}
                audienceType={props.audienceType}
            />
            <Button
                variant="borderless"
                iconName="close"
                className="kc-reader__toolbar-close"
                onClick={props.onCloseClick}
                data-testid="close-button"
            >
                {t("Reader.Close")}
            </Button>
        </div>
    );
};

export const ArticleBrand = (props: ArticleBrandProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="kc-reader__brand">
            <img className="kc-reader__brand-logo" src={Logo} alt="" />
            {props.alternativeUrl && (
                <p>
                    {t("Reader.ViewOnline")}
                    <a href={props.alternativeUrl}>{props.alternativeUrl}</a>
                </p>
            )}
        </div>
    );
};

const ArticleProvenance = (props: ArticleProvenanceProps) => {
    const { t } = useTranslation();
    return (
        <div className="kc-reader__provenance">
            <img className="kc-reader__provenance-logo" src={LogoMark} alt="" />
            {t("Reader.CreatedAndReviewedBy")}
            <a target="_blank" rel="noreferrer" href={formatExternalLink("https://patient.info", props.linkOptions)}>
                Patient.info
            </a>
        </div>
    );
};

const SelectedArticleReader = (props: SelectedArticleReaderProps): JSX.Element => {
    const { trackException } = React.useContext(TelemetryContext);
    const { isFeatureEnabled } = React.useContext(FeatureContext);

    const contentResult = useArticleContentQuery(props.selectedArticle, trackException);
    const linkOptions = {
        source: process.env.APP_CLINICAL_SYSTEM,
        campaign: contentResult.audienceType === AudienceType.Patient ? "patient" : "gp",
    };
    return (
        <>
            <ArticleBrand alternativeUrl={contentResult.externalUrl} />
            <ArticleToolbar
                onBackClick={props.selectPreviousArticle}
                onCloseClick={props.closeArticle}
                canSelectPrevious={props.canSelectPrevious}
                alternativeUrl={contentResult.externalUrl}
                headline={contentResult.headline}
                audienceType={contentResult.audienceType}
            />
            {isFeatureEnabled(Feature.Advertising) && <TargetedBannerAd audienceType={contentResult.audienceType} />}
            <ArticleContent content={contentResult} />
            <ArticleProvenance linkOptions={{ ...linkOptions, medium: "externallinks" }} />
        </>
    );
};

const ArticleReader = (props: ArticleReaderProps): JSX.Element => {
    const { isTokenValid } = React.useContext(TokenContext);
    const { selectedArticle } = React.useContext(ArticleContext);
    return isTokenValid && selectedArticle?.id ? (
        <SelectedArticleReader {...props} selectedArticle={selectedArticle} />
    ) : (
        <></>
    );
};

export default withNavigation(ArticleReader);
