export type LinkOptions = {
    source?: string;
    campaign?: string;
    medium?: string;
};

const formatExternalLink = (link: string, options?: LinkOptions) => {
    if (!options) return link;

    const source = options?.source && `utm_source=${options.source}`;
    const campaign = options?.campaign && `utm_campaign=${options.campaign}`;
    const medium = options?.medium && `utm_medium=${options.medium}`;
    const parameters = [source, campaign, medium].filter(c => typeof c !== "undefined");

    return parameters.length > 0 ? `${link}?${parameters.join("&")}` : link;
};

export default formatExternalLink;
