import React from "react";
import { Feature } from "../components/types";
export type FeatureContextType = {
    isFeatureEnabled: (type: Feature) => boolean;
};
const allFeaturesEnabled = () => true;
const FeaturesContext = React.createContext<FeatureContextType>({
    isFeatureEnabled: allFeaturesEnabled,
});

export default FeaturesContext;
