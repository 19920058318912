import React, { useEffect } from "react";
import { ProgressSpinnerLarge, ProgressSpinnerSmall } from "@emisgroup/ui-kit-react";

type TimedSpinnerProps = {
    loading: boolean;
    text: string;
    error: () => JSX.Element;
    alternate?: () => JSX.Element;
    large?: boolean;
    className?: string;
    delayMs?: number;
    timeoutMs?: number;
};

const MIN_MILLISECONDS_TO_WAIT = 2000;
const MAX_MILLISECONDS_TO_WAIT = 10000;

const useTimers = (
    loading: boolean,
    delay: number,
    timeout: number,
): {
    showSpinner: boolean;
    showError: boolean;
} => {
    const [showSpinner, setShowSpinner] = React.useState(delay <= 0);
    const [showError, setShowError] = React.useState(false);
    useEffect(() => {
        let spinnerTimer: NodeJS.Timeout | null = null;
        let errorTimer: NodeJS.Timeout | null = null;
        if (loading) {
            setShowSpinner(delay <= 0);
            setShowError(false);
            if (delay > 0) spinnerTimer = setTimeout(() => setShowSpinner(true), delay) as unknown as NodeJS.Timer;
            errorTimer = setTimeout(() => setShowError(true), timeout) as unknown as NodeJS.Timer;
        }
        return () => {
            if (errorTimer) clearTimeout(errorTimer);
            if (spinnerTimer) clearTimeout(spinnerTimer);
        };
    }, [loading]);
    return { showSpinner, showError };
};

const TimedSpinner = (props: TimedSpinnerProps): JSX.Element => {
    const delay = props.delayMs ?? MIN_MILLISECONDS_TO_WAIT;
    const timeout = props.timeoutMs ?? MAX_MILLISECONDS_TO_WAIT;

    const { showError, showSpinner } = useTimers(props.loading, delay, timeout);

    if (showError) return props.error();

    const spinner =
        props.large === true ? (
            <ProgressSpinnerLarge text={props.text} className={props.className} />
        ) : (
            <ProgressSpinnerSmall text={props.text} className={props.className} />
        );

    const noSpinner = props.alternate ?? ((): JSX.Element => <></>);
    return showSpinner ? spinner : noSpinner();
};

export default TimedSpinner;
