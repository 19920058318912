import React from "react";
import { Link } from "react-router-dom";
import { getArticlePathFromIdentifier } from "../utilities/pathUtil";
import ArticleContext from "../../context/articleContext";
import PreferencesContext from "../../context/preferencesContext";
import { ArticleType } from "../types";
import { Article } from "./types";
import DrugIcon from "./pill-grey.svg";
import FileIcon from "./file-grey.svg";
import { TelemetryContext } from "../../telemetry";

import "./Style.scss";

type SearchResultProps = {
    result: Article;
};

const SearchResultIcon = (props: SearchResultProps): JSX.Element => {
    const { result } = props;
    return result.type === ArticleType.MedicalDrugArticle ? (
        <img src={DrugIcon} data-testid={`drug-article-icon-${result.id}`} alt="drug article" />
    ) : (
        <img src={FileIcon} data-testid={`default-article-icon-${result.id}`} alt="article" />
    );
};

const SearchResult = (props: SearchResultProps): JSX.Element => {
    const { result } = props;
    const { selectedArticle } = React.useContext(ArticleContext);
    const { currentCookieConsent } = React.useContext(PreferencesContext);
    const { trackSelect } = React.useContext(TelemetryContext);
    const path = getArticlePathFromIdentifier(props.result);

    const handleClick = React.useCallback(() => {
        trackSelect({ path, type: selectedArticle?.type, currentCookieConsent });
    }, [selectedArticle, path, currentCookieConsent, trackSelect]);

    return (
        <Link
            data-testid="kc-search-link"
            to={path}
            className={`kc-search__result${selectedArticle?.id === props.result.id ? " selected" : ""}`}
            onClick={handleClick}
        >
            <SearchResultIcon {...props} />
            {result.name}
        </Link>
    );
};

export default SearchResult;
