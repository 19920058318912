import React from "react";
import additionalCookies from "./additionalCookies";

const PrivacyDetails = () => {
    return (
        <div>
            <h2>What are cookies?</h2>
            <p>
                Cookies are small text files that are stored on your computer to help distinguish you from other users.
                We use the following <b>essential cookies</b>:
            </p>
            <ul>
                <li>
                    <b>Strictly necessary cookies.</b> These are cookies that are needed to make our website work. They
                    include, for example, cookies that allow you to log in.
                </li>
                <li>
                    <b>Analytical cookies.</b> These allow us to recognise and count the number of visitors and to see
                    how visitors use the service and which pages they visit. This helps us improve the way our website
                    works, for example, by ensuring that users can easily find what they&apos;re looking for.
                </li>
                <li>
                    <b>Functionality cookies.</b> These are used to remember you when you return our website. This
                    allows us to personalise our content for you, greet you by name and remember any settings you may
                    have chosen (for example, your choice of language or region).
                </li>
            </ul>
            <p>
                We also use the following <b>additional cookies</b>:
            </p>
            <ul>
                {additionalCookies.map(c => (
                    <li key={c.name}>
                        <p>
                            <b>{c.name}</b> {c.explanation}
                        </p>
                    </li>
                ))}
            </ul>
            <p>
                The essential cookies must always be on, but you can choose whether to accept or reject the additional
                cookies. Rejecting additional cookies will turn off the features associated with those cookies.
            </p>
            <h2>Changing your cookie settings</h2>
            <p>
                You can change your cookie settings at any time by clicking the Privacy link on the bottom left of the
                Information Leaflets screen.
            </p>
        </div>
    );
};

export default PrivacyDetails;
