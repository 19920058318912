import React from "react";

type CookieDetails = {
    name: string;
    explanation: JSX.Element;
};
const additionalCookies: CookieDetails[] = [
    {
        name: "__gads",
        explanation: (
            <>
                {`(Google Ads). These are third party cookies used for advertising purposes. Information Leaflets carries advertising,
            and these cookies allow us to collect information on the numbers of users who visit the pages that show ads.
            We will never share any of your personal information with any advertiser in connection with these cookies.
            The ads shown are not personalised, but instead are relevant to the content on the webpage.`}
            </>
        ),
    },
];

export default additionalCookies;
