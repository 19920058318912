import * as React from "react";
import { NotificationType, ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import Notifications, { NotificationObj } from "../components/Progress/Notifications";

export type NotificationContextType = {
    addNotification: (notification: NotificationObj) => void;
    setLoading: (text: string) => void;
    clearLoading: () => void;
};

const NotificationContext = React.createContext<NotificationContextType | null>(null);

export type NotificationProviderProps = {
    children: React.ReactNode;
};

export const NotificationProvider = (props: NotificationProviderProps) => {
    const [notifications, setNotifications] = React.useState<NotificationObj[]>([]);
    const addNotification = (notification: NotificationObj) => {
        setNotifications(previous => [notification, ...previous]);
    };
    const removeNotification = React.useCallback(
        (notification: NotificationObj) => {
            setNotifications(previous =>
                previous.filter(n => !(n.text === notification.text && n.type === notification.type)),
            );
        },
        [setNotifications],
    );

    const [loadingText, setLoadingText] = React.useState<string>("");
    const setLoading = (text: string) => setLoadingText(text);
    const clearLoading = () => setLoadingText("");

    return (
        <NotificationContext.Provider value={{ addNotification, setLoading, clearLoading }}>
            {props.children}
            <Notifications notifications={notifications} onCloseNotification={removeNotification} />
            {loadingText && <ProgressSpinnerLarge text={loadingText} lightbox data-testid="loadingIndicator" />}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => {
    const ctx = React.useContext(NotificationContext);

    if (!ctx) {
        throw Error("The useNotifications hook must be called from a descendent of the NotificationProvider.");
    }

    return {
        showNotification: (text: string, type: NotificationType) => {
            ctx.addNotification({ text, type });
        },
        setLoading: (text: string) => {
            ctx.setLoading(text);
        },
        clearLoading: () => {
            ctx.clearLoading();
        },
    };
};
