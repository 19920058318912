export function addToSessionStorage(key: string, item: any) {
    if (sessionStorage && typeof item !== "undefined") {
        sessionStorage.setItem(key, JSON.stringify(item));
    }
}

export function getFromSessionStorage(storageKey: string, fallbackValue: any) {
    if (!sessionStorage) {
        return fallbackValue;
    }
    const item = sessionStorage.getItem(storageKey);
    return item ? JSON.parse(item) : fallbackValue;
}
