import React, { useEffect, useState } from "react";
import { SessionContextProvider } from "@emisgroup/application-session-react";
import { ApolloProvider } from "@apollo/client";
import { TelemetryProvider, TelemetryErrorBoundary } from "@emisgroup/clint-telemetry";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "./layout/Index";
import useApolloClient from "./client/useApolloClient";
import { EnvironmentProvider } from "./environmentContext";
import { TelemetryContext, createValue, augmentData } from "./telemetry";
import PreferencesProvider from "./context/PreferencesProvider";
import FeatureProvider from "./context/FeaturesProvider";
import { loadUserCookieConsent, saveUserCookieConsent } from "./components/Privacy/consentUtils";
import TokenProvider from "./context/TokenProvider";
import TokenContext from "./context/tokenContext";
import { PATIENT_SESSION_ITEM } from "./components/patient";
import { handlePatientChanged } from "./utilities/patientChangeUtils";
import { ISessionContext } from "@emisgroup/application-session-management";

const DEV_APP_INSIGHTS_KEY_PLACEHOLDER = "<application insights instrumentation key>";

export interface IApplicationProps {
    isRunningInApplicationFrame: boolean;
    sessionContext: ISessionContext;
}

const WithApolloProvider = ({ children }) => {
    const { token } = React.useContext(TokenContext);
    const client = React.useMemo(() => useApolloClient(token), [token]);

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

/**
 * Responsible for rendering the Application component
 */
const Application = (props: IApplicationProps): JSX.Element => {
    const history = useLocation();
    const navigate = useNavigate();
    const { sessionContext, isRunningInApplicationFrame } = props;

    /**
     * This section should be used to setup the application when the application
     * is navigated to in the frame.
     */
    const onApplicationMount = (): void => {
        handlePatientChanged(
            sessionContext.GetItem(PATIENT_SESSION_ITEM) as string,
            history.pathname,
            history.search,
            sessionStorage,
            (to: string) => navigate(to, { replace: true }),
        );
    };

    /**
     * This section should is used to clean up when the application
     * is navigated away from in the frame.
     */
    const onApplicationUnMount = (): void => {
        //
    };

    useEffect(() => {
        onApplicationMount();

        return (): void => {
            onApplicationUnMount();
        };
    });

    const loadedConsent = loadUserCookieConsent();
    const [cookieConsent, setCookieConsent] = useState<boolean | undefined>(loadedConsent);

    const notifyAndSetCookieConsent = preference => {
        saveUserCookieConsent(preference);
        setCookieConsent(preference);
    };

    const layout = <Layout />;
    const wrappedLayout = isRunningInApplicationFrame ? layout : <div style={{ height: "100vh" }}>{layout}</div>;
    const appInsightsKey =
        process.env.APP_INSTRUMENTATION_KEY && process.env.APP_INSTRUMENTATION_KEY !== DEV_APP_INSIGHTS_KEY_PLACEHOLDER
            ? process.env.APP_INSTRUMENTATION_KEY
            : "";
    return (
        <TelemetryErrorBoundary>
            <EnvironmentProvider isRunningInApplicationFrame={isRunningInApplicationFrame}>
                <SessionContextProvider value={sessionContext}>
                    <TelemetryProvider
                        appInsightsKey={appInsightsKey}
                        context={TelemetryContext}
                        createContextValue={createValue}
                        augmentData={augmentData}
                        enableInDev
                        applicationName="information-leaflets"
                    >
                        <TokenProvider sessionContext={sessionContext}>
                            <PreferencesProvider
                                currentCookieConsent={cookieConsent}
                                setCookieConsent={notifyAndSetCookieConsent}
                            >
                                <FeatureProvider>
                                    <WithApolloProvider>{wrappedLayout}</WithApolloProvider>
                                </FeatureProvider>
                            </PreferencesProvider>
                        </TokenProvider>
                    </TelemetryProvider>
                </SessionContextProvider>
            </EnvironmentProvider>
        </TelemetryErrorBoundary>
    );
};

export default Application;
