import * as React from "react";
import { IntlProvider } from "./IntlProvider";
import Application from "./App";
import {
    AddEmisWebEventHandlers,
    RemoveEmisWebEventHandlers,
    AddPatientIdpEventHandlers,
    RemovePatientIdpEventHandlers,
} from "./events/handlers";
import { IdpType, TokenState } from "@emisgroup/application-session-management";
import { ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import GetSessionContext from "./session/getSessionContext";
import { BrowserRouter } from "react-router-dom";

export const SignInPath = "/signin";

/**
 * Responsible for rendering the component as per route path
 */
export const AppLocal: React.FC = () => {
    const [sessionContext] = React.useState(GetSessionContext());
    const idpType = process.env.APP_IDP_MODE === "Patient" ? IdpType.Patient : IdpType.Emis;
    const patientMode = idpType === IdpType.Patient;

    const [isAuthenticated, setIsAuthenticated] = React.useState(
        sessionContext.accessToken.idp === idpType && sessionContext.accessToken.state === TokenState.Valid,
    );
    React.useEffect(() => {
        const handlerProps = { sessionContext, setIsAuthenticated };
        if (patientMode) AddPatientIdpEventHandlers(handlerProps);
        else AddEmisWebEventHandlers(handlerProps);
        return () => {
            if (patientMode) RemovePatientIdpEventHandlers();
            else RemoveEmisWebEventHandlers();
        };
    }, []);

    if (!isAuthenticated)
        return <ProgressSpinnerLarge text={`Waiting for ${patientMode ? "Patient" : "Emis"} authentication`} />;

    return (
        <IntlProvider>
            <BrowserRouter>
                <Application isRunningInApplicationFrame={false} sessionContext={sessionContext} />
            </BrowserRouter>
        </IntlProvider>
    );
};

export default AppLocal;
