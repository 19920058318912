import React from "react";
import useScript from "./useScript";

declare const window: any;

const adScript = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

const bannerAdvert = {
    id: "div-gpt-ad-1622716636714-0",
    path: "/1008730/EMIS-Leaderboard",
    size: [728, 90],
};

let slot;

const useAdSlot = ({ path, size, id, adsReady }) => {
    React.useEffect(() => {
        if (adsReady) {
            const googleTag = window.googletag || {};
            googleTag.cmd = googleTag.cmd || [];
            googleTag.cmd.push(() => {
                if (!slot) {
                    slot = googleTag.defineSlot(path, size, id);
                    slot?.addService(googleTag.pubads());
                } else googleTag.pubads().refresh();
                googleTag.pubads().setForceSafeFrame(true);
                googleTag.pubads().enableSingleRequest();
                googleTag.enableServices();
            });
            googleTag.cmd.push(() => {
                googleTag.display(id);
            });
        }
    }, [path, size, id, adsReady]);
};

const useAdverts = (cookieConsent: boolean) => {
    const status = useScript(adScript);
    useAdSlot({ ...bannerAdvert, adsReady: cookieConsent && status === "ready" });
    return bannerAdvert.id;
};

export default useAdverts;
