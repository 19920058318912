import { ArticleType, DisallowedArticleTypes, IArticleIdentifier } from "../types";

export const routedArticleTypes = () =>
    Object.values(ArticleType).filter((a: ArticleType) => !DisallowedArticleTypes.includes(a));
export const getArticleTypeFromPath = (articleType: string): ArticleType | undefined => {
    const comparison = articleType.toLowerCase();
    let result: ArticleType | undefined;
    routedArticleTypes().forEach((a: ArticleType) => {
        if ((a as string).toLowerCase() === comparison) result = a;
    });
    return result;
};

export const getArticleFromPath = (articleSpec: string) => {
    let initialArticle: IArticleIdentifier | undefined;
    if (articleSpec) {
        const spec = articleSpec.split(":");
        const article = {
            type: spec.length >= 1 ? getArticleTypeFromPath(spec[0]) : undefined,
            id: spec.length >= 2 ? spec[1] : undefined,
        };
        if (article.type && article.id) {
            initialArticle = article as IArticleIdentifier;
        }
    }
    return initialArticle;
};

export const getArticlePath = (articleType: string, articleId: string) => `/${articleType}:${articleId}`;

export const getArticlePathFromIdentifier = (article: IArticleIdentifier) => getArticlePath(article.type, article.id);
