import React from "react";

export type PreferencesContextType = {
    setCookieConsent: (consent: boolean) => void;
    currentCookieConsent?: boolean;
};

const PreferencesContext = React.createContext<PreferencesContextType>({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCookieConsent: _ => {
        /* Intentional */
    },
});

export default PreferencesContext;
