import { gql, useQuery } from "@apollo/client";
import { ArticleType, AudienceType } from "../types";
import { routedArticleTypes } from "../utilities/pathUtil";
import { searchResultDistribution } from "./searchResultUtils";
import { QueryResults } from "./types";

const QUERY_SIZE = 12; // default page of results
const CHUNK_SIZE = 3; // default interleaving factor for each article type. Should divide QUERY_SIZE wholly for best UX

const getArticleTypeQuery = (audienceType: AudienceType, articleType: ArticleType, querySize: number) => `
    ${articleType}: search(term: $term skip: $skipCount first: ${querySize}, audienceType: ${audienceType} type: ${articleType}) {
        queryTime
        totalResultCount
        results {
            audience {
                audienceType
            }
            id
            name
            type
        }
    }`;

const useArticleQuery = (
    term: string,
    audienceType: AudienceType,
    chunkSize: number = CHUNK_SIZE,
    querySize: number = QUERY_SIZE,
): QueryResults => {
    const handleError = (error: Error): QueryResults => {
        return {
            loading: false,
            results: [],
            totalResultCount: 0,
            error: error.message,
        };
    };
    try {
        // Build a composite query for each article type
        const articleTypes = routedArticleTypes();
        const query = `
        query ArticleQuery($term: String!, $skipCount: Int) {
            ${articleTypes.map(articleType => getArticleTypeQuery(audienceType, articleType, querySize)).join(" ")}
        }`;
        const { data, error, loading, fetchMore } = useQuery(gql(query), { variables: { term, skipCount: 0 } });

        if (term.trim() === "") return handleError(new Error("error-code:no-term-provided"));
        if (error) return handleError(error);

        // Distribute the search results so each type gets fair representation
        const resultSets = articleTypes.map(articleType => (data ? data[`${articleType}`] : { results: [] }));
        const results = searchResultDistribution(resultSets, chunkSize);
        const totalResultCount = resultSets.map(r => r?.totalResultCount ?? 0).reduce((a, b) => a + b, 0);

        // Configure the next page to skip past the end point of largest result sets so far (i.e. ones that haven't run out)
        const hasNextPage = results.length < totalResultCount;
        const skipCount = Math.max(...resultSets.map(r => r.results.length));
        const nextPage = () => fetchMore({ variables: { term, skipCount } });

        return {
            results,
            loading,
            audienceTypes: [audienceType],
            totalResultCount,
            hasNextPage,
            nextPage,
        };
    } catch (ex) {
        return handleError(ex);
    }
};

export default useArticleQuery;
