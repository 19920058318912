import { addToSessionStorage, getFromSessionStorage } from "../utilities/sessionStorageUtils";

export const ARTICLE_BOOKMARKS_KEY = "article-bookmarks";

export type ArticleBookmark = {
    page: string;
    link: string;
};

export const tryPopBookmark = (currentPage: string): ArticleBookmark | undefined => {
    const bookmarks = getFromSessionStorage(ARTICLE_BOOKMARKS_KEY, []) as ArticleBookmark[];
    const bookmark = bookmarks?.pop();
    if (bookmark?.page === currentPage) {
        addToSessionStorage(ARTICLE_BOOKMARKS_KEY, bookmarks);
        return bookmark;
    }
    return undefined;
};

export const pushBookmark = (page: string, link: string) => {
    const bookmarks = getFromSessionStorage(ARTICLE_BOOKMARKS_KEY, []) as ArticleBookmark[];
    bookmarks.push({ page, link });
    addToSessionStorage(ARTICLE_BOOKMARKS_KEY, bookmarks);
};
