import { useTranslation } from "@emisgroup/application-intl";
import { InfoError } from "@emisgroup/icons-react";
import React from "react";
import "./Style.scss";

type ErrorProps = {
    title: string;
    detail: string;
    className?: string;
};

const Error = (props: ErrorProps): JSX.Element => {
    const { t } = useTranslation();
    const title = t(props.title);
    return (
        <div className={props.className}>
            <InfoError title={title} />
            <div className="kc-error-title">{title}</div>
            <div className="kc-error-text">{t(props.detail)}</div>
        </div>
    );
};

export default Error;
