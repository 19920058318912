import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-kit-react";
import copy from "copy-text-to-clipboard";
import React from "react";
import { useLocation } from "react-router";
import { useNotifications } from "../../context/notificationProvider";
import { TelemetryContext } from "../../telemetry";
import { AudienceType, Feature } from "../types";
import ConfirmationDialog from "./ConfirmationDialog";
import useConsultation, { IssueMethod } from "./useConsultation";
import FeatureContext from "../../context/featuresContext";

export type CopyLinkButtonProps = { alternativeUrl?: string; headline?: string; audienceType?: AudienceType };

const CopyLinkButton = (props: CopyLinkButtonProps) => {
    const { alternativeUrl, audienceType, headline } = props;
    const { t } = useTranslation();
    const { trackCopy } = React.useContext(TelemetryContext);
    const { pathname: path } = useLocation();
    const { showNotification, setLoading, clearLoading } = useNotifications();
    const postConsultation = useConsultation({
        name: headline || "",
        url: alternativeUrl || "",
        issueMethod: IssueMethod.Copy,
        audienceType,
        onCompleted: (success: boolean) => {
            clearLoading();
            if (success) {
                showNotification(t("Reader.SavedToRecord"), "confirmation");
            } else {
                showNotification(t("Reader.NotSavedToRecord"), "error");
            }
        },
    });

    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
    const handleConfirmation = React.useCallback(
        (confirmed: boolean) => {
            if (confirmed) {
                trackCopy({ path });
                setLoading(t("Consultation.InProgress"));
                postConsultation(new Date());
            }
            setShowConfirmationDialog(false);
        },
        [postConsultation, trackCopy, setLoading, setShowConfirmationDialog],
    );

    const { isFeatureEnabled } = React.useContext(FeatureContext);
    const onClose = React.useCallback(() => setShowConfirmationDialog(false), [setShowConfirmationDialog]);
    const copyLink = React.useCallback(() => {
        if (alternativeUrl && copy(alternativeUrl)) {
            if (isFeatureEnabled(Feature.WriteToRecord)) setShowConfirmationDialog(true);
            else showNotification(t("Reader.LinkCopied"), "confirmation");
        }
    }, [alternativeUrl, setShowConfirmationDialog, isFeatureEnabled]);
    return alternativeUrl ? (
        <>
            <Button onClick={copyLink} variant="borderless" iconName="link">
                {t("Reader.CopyLink")}
            </Button>
            <ConfirmationDialog
                yesText={t("Reader.AddToRecord")}
                noText={t("Reader.DoNotAdd")}
                title={t("Reader.InformationLeaflets")}
                content={t("Reader.CopyLinkClinicalRecordConfirmation")}
                open={showConfirmationDialog}
                onClose={onClose}
                onConfirmed={handleConfirmation}
            />
        </>
    ) : (
        <></>
    );
};

export default CopyLinkButton;
