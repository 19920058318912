import { Article } from "./types";

const addChunk = (startIndex, resultSet, results, chunkSize) => {
    for (let i = startIndex; i < resultSet.results.length && i < startIndex + chunkSize; i += 1)
        results.push(resultSet.results[i]);
};

const interleaveChunks = (resultSets, resultIndex, results, chunkSize) => {
    let index = resultIndex;
    resultSets.forEach(resultSet => addChunk(index, resultSet, results, chunkSize));
    index += chunkSize;
    return index;
};

export const searchResultDistribution = (resultSets, chunkSize) => {
    const resultSetCounts = resultSets.map(r => r?.results?.length ?? 0);
    const resultCount = resultSetCounts.reduce((a, b) => a + b, 0);
    const results = [];
    let resultIndex = 0;
    while (results.length < resultCount) resultIndex = interleaveChunks(resultSets, resultIndex, results, chunkSize);

    return results;
};

const ANCHOR_MARGIN_LEFT = "30px";
const ANCHOR_MARGIN_RIGHT = "17px";
const ANCHOR_MIN_HEIGHT = "39px";

export const getSearchResultSize = ({ name }: Article, parentElement: Element) => {
    const anchor = document.createElement("a");
    anchor.className = "kc-search__result";
    anchor.text = name;
    anchor.style.opacity = "0";
    anchor.style.marginLeft = ANCHOR_MARGIN_LEFT;
    anchor.style.marginRight = ANCHOR_MARGIN_RIGHT;
    anchor.style.minHeight = ANCHOR_MIN_HEIGHT;

    parentElement.appendChild(anchor);
    const { height } = anchor.getBoundingClientRect();
    parentElement.removeChild(anchor);

    return height;
};
