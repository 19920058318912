export enum ArticleType {
    Article = "article",
    MedicalArticle = "medicalArticle",
    MedicalDrugArticle = "medicalDrugArticle",
    Recipe = "recipe",
}

export const DisallowedArticleTypes = [ArticleType.Recipe, ArticleType.Article];

export enum AudienceType {
    Patient = "patient",
    MedicalProfessional = "medicalProfessional",
}
export interface IArticleIdentifier {
    id: string;
    type: ArticleType;
}

export enum Feature {
    WriteToRecord = "writeToRecord",
    SendToPatient = "sendToPatient",
    Advertising = "advertising",
}
