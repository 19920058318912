import * as React from "react";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import SearchResult from "../SearchResult";
import { getSearchResultSize } from "../searchResultUtils";
import { Article } from "../types";

type ScrollingResultsProps = {
    items: Article[];
    hasNextPage?: boolean;
    isNextPageLoading?: boolean;
    loadNextPage?: () => void;
    initialHeight?: number;
};

const EMPTY_FUNC = () => {
    // This is intentional
};

/* eslint-disable react/jsx-no-bind */
const ScrollingResults = ({
    hasNextPage,
    isNextPageLoading,
    items,
    loadNextPage,
    initialHeight,
}: ScrollingResultsProps) => {
    const itemCount = hasNextPage ? items.length + 1 : items.length;
    const loadMoreItems = isNextPageLoading ? EMPTY_FUNC : loadNextPage;
    const isItemLoaded = index => !hasNextPage || index < items.length;
    const [height, setHeight] = React.useState(initialHeight ?? 0);
    const articlesRef = React.useRef<HTMLDivElement>(null);
    const listRef = React.useRef<VariableSizeList | null>(null);

    React.useLayoutEffect(() => {
        const articlesDiv = articlesRef.current as HTMLDivElement;
        const resizeHandler = () => {
            setHeight(articlesDiv.getBoundingClientRect().height);
            listRef.current?.resetAfterIndex(0);
        };

        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(articlesDiv);
        return () => resizeObserver.unobserve(articlesDiv);
    }, []);

    const Item = ({ index, style }) => {
        let content;
        if (!isItemLoaded(index)) {
            content = "Loading...";
        } else {
            const a = items[index];
            content = <SearchResult key={a.id} result={a} />;
        }

        return <div style={style}>{content}</div>;
    };

    const getItemSize = (itemIndex: number) => {
        const item = items[itemIndex];

        return item ? getSearchResultSize(item, document.getElementsByClassName("kc-search__group")[0]) : 0;
    };

    return (
        <div className="kc-search__group-articles" ref={articlesRef} data-testid="articles-list">
            {items.length > 0 && height > 0 && (
                <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
                    {({ onItemsRendered, ref }) => (
                        <VariableSizeList
                            itemCount={items.length}
                            onItemsRendered={onItemsRendered}
                            ref={list => {
                                ref(list);
                                listRef.current = list;
                            }}
                            height={height}
                            width="100%"
                            itemSize={getItemSize}
                        >
                            {Item}
                        </VariableSizeList>
                    )}
                </InfiniteLoader>
            )}
        </div>
    );
};

export default ScrollingResults;
