import * as React from "react";
import { useParams, useLocation } from "react-router";
import { useSessionContext } from "@emisgroup/application-session-react";
import { IElementProps } from "../../Types";
import { getArticleFromPath } from "../utilities/pathUtil";
import { Search } from "../Search/Search";
import ArticleProvider from "../../context/ArticleProvider";
import { NotificationProvider } from "../../context/notificationProvider";
import ArticleReader from "../Reader/ArticleReader";
import packageJson from "../../../package.json";
import usePacExtension from "../usePacExtension";
import { TelemetryContext } from "../../telemetry";

import "./Style.scss";

type Props = IElementProps;

const NO_ARTICLE_TITLE = "Information Leaflets";
export const Home: React.FC<Props> = (props: Props) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [initialTerm] = React.useState(query.get("term") ?? "");

    usePacExtension();
    const { articleSpec } = useParams() as {
        articleSpec: string;
    };
    const initialArticle = getArticleFromPath(articleSpec);
    const sessionContext = useSessionContext();
    const { trackMissingOrgName } = React.useContext(TelemetryContext);

    React.useEffect(() => {
        const orgName = sessionContext.userClaims?.orgName;
        if (!orgName || orgName.length === 0) {
            trackMissingOrgName({});
        }
    }, []);

    if (!initialArticle) document.title = NO_ARTICLE_TITLE;
    return (
        <ArticleProvider initialArticle={initialArticle}>
            <div className={props.className}>
                <NotificationProvider>
                    <main className={`${props.className}__article`}>
                        <ArticleReader />
                    </main>
                    <nav className={`${props.className}__search`}>
                        <Search {...props} initialSearch={{ term: initialTerm }} key={initialTerm || "__BLANK__"} />
                        <div className={`${props.className}__version`}>{`Version: ${packageJson.version}`}</div>
                    </nav>
                </NotificationProvider>
            </div>
        </ArticleProvider>
    );
};

Home.defaultProps = {
    className: "kc-home",
};

export default Home;
