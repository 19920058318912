import { Home } from "../components/Home/Index";

export interface IRoutesData {
    /**
     * Should be displayed on the home page
     */
    component: React.ComponentType<any>;
    /**
     * Should be displayed on the home page
     */
    path: string;
}

const RoutesData: IRoutesData[] = [
    {
        component: Home,
        path: "/:articleSpec?",
    },
];

export default RoutesData;
