import { useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { AudienceType, IArticleIdentifier } from "../types";
import { ExceptionTelemetry } from "../../telemetry";
export type ContentReview = {
    lastReviewDate?: string;
    nextReviewDate?: string;
};
export type CreatorDetails = { name?: string; url?: string };
export type ReviewedByDetails = { name?: string; url?: string };
export type ContentResult = {
    loading: boolean;
    content: string;
    headline: string;
    review?: ContentReview;
    audienceType?: AudienceType;
    introduction?: string;
    externalUrl?: string;
    error?: string;
    version?: string;
    documentId?: string;
    creator?: CreatorDetails;
    reviewedBy?: ReviewedByDetails;
};

const validateArticleContent = (articleContent: any) => {
    return articleContent && articleContent.headline && articleContent.text;
};

const toDisplayDate = (date?: string) =>
    date ? new Date(date).toLocaleDateString("en-GB", { year: "numeric", month: "short", day: "numeric" }) : undefined;

const toDocumentId = (values?: { value: string }[]) =>
    values?.length === 1 ? values[0].value.split("-")[1] : undefined;
const CREATED_BY = `
    fragment CreatedBy on Person {
        name
        url
    }
`;
const REVIEWED_BY = `
    fragment ReviewedBy on Person {
        name
        url
    }
`;
const getArticleQuery = (article: IArticleIdentifier) => ({
    document: `
${CREATED_BY}
${REVIEWED_BY}
query ArticleContentQuery($id: String!) {
    ${article.type}(where: { id: $id }) {
        text(urlKind: identifier)
        lastReviewed
        nextReviewed
        printableCopy
        headline
        version
        introduction
        creator {
            ...CreatedBy
        }
        reviewedBy {
            ...ReviewedBy
        }
        audience {
            audienceType
        }
        identifier(name: "MedicalAuthors") {
            value
        }
    }
}`,
    variables: { id: article.id },
});

const useArticleContentQuery = (
    article: IArticleIdentifier,
    trackException?: (event: ExceptionTelemetry) => void,
): ContentResult => {
    const ref = useRef({
        data: undefined as any,
        result: { loading: false } as ContentResult,
    });

    const handleError = (error: Error): ContentResult => {
        error.message = `${error.message} (article content)`;
        if (trackException) trackException({ exception: error, properties: { ...article } });
        return {
            content: "",
            headline: "",
            loading: false,
            error: error.message,
        };
    };

    try {
        const query = getArticleQuery(article);

        const { data, error, loading } = useQuery(gql(query.document), query);
        if (error) return handleError(error);

        if (!loading && data === ref.current.data) return ref.current.result;
        ref.current.data = data;

        const articleContent = data ? data[`${article.type}`]?.[0] : undefined;
        if (!loading && !validateArticleContent(articleContent))
            return handleError(new Error("Article was missing required content"));

        const version = articleContent?.version ? ` (v${articleContent.version})` : "";
        const title = articleContent?.headline ? ` - ${articleContent.headline}` : "";
        document.title = `Information Leaflets${title}${version}`;
        ref.current.result = {
            loading,
            headline: articleContent?.headline,
            review: {
                lastReviewDate: toDisplayDate(articleContent?.lastReviewed),
                nextReviewDate: toDisplayDate(articleContent?.nextReviewed),
            },
            content: articleContent?.text,
            introduction: articleContent?.introduction,
            externalUrl: articleContent?.printableCopy,
            audienceType: articleContent?.audience?.audienceType,
            version: articleContent?.version,
            creator: { name: articleContent?.creator?.name, url: articleContent?.creator?.url },
            reviewedBy: { name: articleContent?.reviewedBy?.name, url: articleContent?.reviewedBy?.url },
            documentId: toDocumentId(articleContent?.identifier),
        };
        return ref.current.result;
    } catch (ex) {
        return handleError(ex);
    }
};

export default useArticleContentQuery;
