import React from "react";
import useAdverts from "../../adverts/useAdverts";
import PreferencesContext from "../../context/preferencesContext";
import { AudienceType } from "../types";

type TargetedBannerAdProps = {
    audienceType?: AudienceType;
};

const targetAudiences = [AudienceType.MedicalProfessional];

const isTargetAudience = (audienceType?: AudienceType) => audienceType && targetAudiences.includes(audienceType);

const BannerAd = () => {
    const { currentCookieConsent } = React.useContext(PreferencesContext);
    const advertId = useAdverts(Boolean(currentCookieConsent));
    return <div id={advertId} className="kc-advert-banner" />;
};

const TargetedBannerAd = (props: TargetedBannerAdProps) => {
    return isTargetAudience(props.audienceType) ? <BannerAd /> : <></>;
};

export default TargetedBannerAd;
