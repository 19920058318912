import { useSessionContext } from "@emisgroup/application-session-react";
import { useCallback, useContext } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import moment from "moment";
import { getPatientIdentifier } from "../patient";
import { ExceptionTelemetry, TelemetryContext } from "../../telemetry";
import { AudienceType } from "../types";
import fetchWithRetry from "../../utilities/fetchWithRetry";
const SOURCE_CODE_ID = 87941000000117;
const SECTION_TYPE = "Comment";
const DATE_TIME_MASK = "YYYY-MM-DDTHH:mm:ss";

export enum IssueMethod {
    Print,
    Copy,
    Email,
}

function issueMethodText(t: (phrase: string) => string, issueMethod: IssueMethod) {
    switch (issueMethod) {
        case IssueMethod.Copy:
            return t("Consultation.IssueCopied");
        case IssueMethod.Email:
            return t("Consultation.IssueEmailed");
        case IssueMethod.Print:
            return t("Consultation.IssuePrinted");
        default:
            return "";
    }
}

const MAX_ATTEMPTS = 1;
async function sendConsultation(
    trackException: (event: ExceptionTelemetry) => void,
    token: string,
    patientIdentifier: string,
    consultation: any,
    onCompleted: (success: boolean) => void,
) {
    try {
        const response = await fetchWithRetry(
            `${process.env.APP_OPEN_API_URL}/patients/${patientIdentifier}/carerecord/consultations`,
            {
                method: "POST",
                body: JSON.stringify(consultation),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            },
            MAX_ATTEMPTS,
            1000,
        );

        if (response.ok) {
            onCompleted(true);
        } else {
            const responseText = await response.text();
            trackException({
                exception: new Error(
                    `Failed to send consultation: status:${response.status}, statusText:${response.statusText}, responseText:${responseText}`,
                ),
            });
            onCompleted(false);
        }
    } catch (e) {
        e.message = `${e.message} (send consultation)`;
        trackException({ exception: e });
        onCompleted(false);
    }
}

type UseConsultationOptions = {
    name: string;
    url: string;
    issueMethod: IssueMethod;
    audienceType?: AudienceType;
    onCompleted: (success: boolean) => void;
};
export default function useConsultation({ name, url, issueMethod, audienceType, onCompleted }: UseConsultationOptions) {
    const sessionContext = useSessionContext();
    const { trackException } = useContext(TelemetryContext);
    const { t } = useTranslation();
    return useCallback(
        (currentDateTime: Date) => {
            const issuedText = t(
                audienceType === AudienceType.Patient
                    ? "Consultation.PatientInformationLeaflet"
                    : "Consultation.ProfessionalInformationLeaflet",
            );
            const effectiveDateTime = moment(currentDateTime).format(DATE_TIME_MASK);
            const patientIdentifier = getPatientIdentifier(sessionContext);
            const consultation = {
                effectiveDateTime,
                sourceCodeId: SOURCE_CODE_ID,
                pages: [
                    {
                        sections: [
                            {
                                sectionType: SECTION_TYPE,
                                events: [
                                    {
                                        observation: {
                                            associatedText: [
                                                `${issuedText} ${issueMethodText(t, issueMethod)} - ${name} (${url})`,
                                            ],
                                            effectiveDateTime,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };

            return sendConsultation(
                trackException,
                sessionContext?.accessToken?.value || "",
                patientIdentifier,
                consultation,
                onCompleted,
            );
        },
        [sessionContext, name, url, issueMethodText, issueMethod, audienceType],
    );
}
