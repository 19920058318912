import * as React from "react";
import { SearchBox } from "@emisgroup/ui-kit-react";
import { InfoNotification } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import { IElementProps } from "../../Types";
import SearchResults from "./SearchResults";
import { SearchParameters } from "./types";
import Filters from "./Filters";
import { AudienceType, Feature } from "../types";
import PrivacyPanel from "../Privacy/PrivacyPanel";
import TokenContext from "../../context/tokenContext";

import "./Style.scss";
import FeaturesContext from "../../context/featuresContext";

type Props = IElementProps & { initialSearch: SearchParameters };

const EnterSearchTerm = (props: { title: string }): JSX.Element => (
    <div className="kc-search__info">
        <InfoNotification title={props.title} ariaHidden />
        <div className="kc-search__info">{props.title}</div>
    </div>
);

export const Search: React.FC<Props> = (props: Props) => {
    const [searchParameters, setSearchParameters] = React.useState<SearchParameters>(props.initialSearch);
    const { refreshToken, isTokenValid } = React.useContext(TokenContext);
    const { isFeatureEnabled } = React.useContext(FeaturesContext);

    const { t } = useTranslation();

    const onSearch = React.useCallback(
        async (term: string) => {
            await refreshToken();
            setSearchParameters({ term });
        },
        [refreshToken, setSearchParameters],
    );

    const onSearchClear = React.useCallback(() => setSearchParameters({ term: "" }), [setSearchParameters]);

    const setAudienceFilter = (a: AudienceType): void =>
        setSearchParameters({
            ...searchParameters,
            audienceTypes: [a],
        });

    const hasFilters = searchParameters.audienceTypes && searchParameters.audienceTypes.length > 0;

    const audienceTypesToDisplay =
        searchParameters.audienceTypes && hasFilters ? searchParameters.audienceTypes : Object.values(AudienceType);

    const validTerm = searchParameters.term.trim() !== "" && searchParameters.term.length > 2;

    return (
        <div className="kc-search">
            <SearchBox
                disabled={hasFilters}
                className="kc-search__box"
                searchText={searchParameters.term}
                alignment="above"
                debounce={500}
                hideButton
                hideLabel
                label="Search for articles"
                data-testid="search-box"
                onSearch={onSearch}
                onSearchClear={onSearchClear}
            />
            <Filters searchParameters={searchParameters} setSearchParameters={setSearchParameters} />
            <div
                className={`kc-search__groups ${audienceTypesToDisplay.length > 1 ? "kc-search__multiple-groups" : ""}`}
            >
                {isTokenValid && validTerm ? (
                    audienceTypesToDisplay.map((a: AudienceType) => (
                        <SearchResults
                            key={a}
                            term={searchParameters.term}
                            audienceType={a}
                            showingMore={searchParameters.audienceTypes?.includes(a)}
                            // eslint-disable-next-line react/jsx-no-bind
                            seeMoreResultsClick={() => setAudienceFilter(a)}
                        />
                    ))
                ) : (
                    <EnterSearchTerm title={t("Search.EnterTerm")} />
                )}
            </div>
            {isFeatureEnabled(Feature.Advertising) && <PrivacyPanel />}
        </div>
    );
};

export default Search;
