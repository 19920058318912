import * as React from "react";
import { TelemetryContext } from "../../telemetry";
import { AudienceType } from "../types";
import { QueryResults } from "./types";

type UseSearchMetricsParams = { term: string; audienceType: AudienceType; query: QueryResults };

export default function useSearchMetrics({ term, audienceType, query }: UseSearchMetricsParams) {
    const { trackSearch, trackException } = React.useContext(TelemetryContext);
    const queryStartTimeMs = React.useRef<number | null>(null);
    React.useEffect(() => {
        switch (true) {
            case query.loading:
                queryStartTimeMs.current = new Date().getTime();
                break;
            case Boolean(query.error):
                trackException({
                    exception: new Error(`${query.error} (article search)` || "Unknown error"),
                    properties: {
                        term,
                        audienceType,
                        timeMs: new Date().getTime() - Number(queryStartTimeMs.current),
                    },
                });
                break;
            case queryStartTimeMs.current !== null:
                trackSearch({
                    term,
                    audienceType,
                    timeMs: new Date().getTime() - Number(queryStartTimeMs.current),
                    results: query.results,
                });
                queryStartTimeMs.current = null;
                break;
            default:
                break;
        }
    }, [query.loading, query.error]);
}
