import React, { ReactNode } from "react";
import PreferencesContext from "./preferencesContext";

type PreferencesProviderProps = {
    children: ReactNode;
    setCookieConsent: (consent: boolean) => void;
    currentCookieConsent?: boolean;
};

const PreferencesProvider = ({
    children,
    currentCookieConsent,
    setCookieConsent,
}: PreferencesProviderProps): JSX.Element => {
    return (
        <PreferencesContext.Provider
            value={{
                currentCookieConsent,
                setCookieConsent,
            }}
        >
            {children}
        </PreferencesContext.Provider>
    );
};

export default PreferencesProvider;
