import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-kit-react";
import React from "react";
import { useLocation } from "react-router";
import { useNotifications } from "../../context/notificationProvider";
import { TelemetryContext } from "../../telemetry";
import { AudienceType, Feature } from "../types";
import ConfirmationDialog from "./ConfirmationDialog";
import useConsultation, { IssueMethod } from "./useConsultation";
import FeatureContext from "../../context/featuresContext";

export type PrintArticleButtonProps = {
    alternativeUrl?: string;
    headline?: string;
    audienceType?: AudienceType;
};

const PrintArticleButton = ({ alternativeUrl, headline, audienceType }: PrintArticleButtonProps) => {
    const { t } = useTranslation();
    const { trackPrint } = React.useContext(TelemetryContext);
    const { pathname: path } = useLocation();
    const { showNotification, setLoading, clearLoading } = useNotifications();
    const postConsultation = useConsultation({
        name: headline || "",
        url: alternativeUrl || "",
        issueMethod: IssueMethod.Print,
        audienceType,
        onCompleted: (success: boolean) => {
            clearLoading();
            if (success) {
                showNotification(t("Reader.SavedToRecord"), "confirmation");
            } else {
                showNotification(t("Reader.NotSavedToRecord"), "error");
            }
        },
    });
    const { isFeatureEnabled } = React.useContext(FeatureContext);
    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
    const handleClick = React.useCallback(() => {
        window.open(alternativeUrl);
        if (isFeatureEnabled(Feature.WriteToRecord)) setShowConfirmationDialog(true);
    }, [alternativeUrl, setShowConfirmationDialog, isFeatureEnabled]);
    const handleConfirmation = React.useCallback(
        (confirmed: boolean) => {
            if (confirmed) {
                trackPrint({ path });
                setLoading(t("Consultation.InProgress"));
                postConsultation(new Date());
            }
            setShowConfirmationDialog(false);
        },
        [setShowConfirmationDialog, postConsultation, setLoading, trackPrint],
    );

    const onClose = React.useCallback(() => setShowConfirmationDialog(false), [setShowConfirmationDialog]);

    return alternativeUrl ? (
        <>
            <Button onClick={handleClick} variant="borderless" iconName="print">
                {t("Reader.Print")}
            </Button>
            <ConfirmationDialog
                yesText={t("Reader.AddToRecord")}
                noText={t("Reader.DoNotAdd")}
                title={t("Reader.InformationLeaflets")}
                content={t("Reader.PrintLeafletClinicalRecordConfirmation")}
                open={showConfirmationDialog}
                onClose={onClose}
                onConfirmed={handleConfirmation}
            />
        </>
    ) : (
        <></>
    );
};

export default PrintArticleButton;
