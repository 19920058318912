import Cookies from "js-cookie";
import additionalCookies from "./additionalCookies";

const CONSENT_COOKIE_EXPIRY = 365;
const CONSENT_COOKIE = "user-additional-cookie-consent";
const COOKIE_UPDATE_EVENT = "EmisWeb_InboundEvent";
const COOKIE_UPDATE_TYPE = "ApplicationFrame_AdCookiesSet";

/* istanbul ignore next */
const defaultLoad = () => Cookies.get(CONSENT_COOKIE);
/* istanbul ignore next */
const defaultSave = (preference, detail) => Cookies.set(CONSENT_COOKIE, preference, detail);
/* istanbul ignore next */
const defaultNotify = event => document.dispatchEvent(new CustomEvent(COOKIE_UPDATE_EVENT, event));
/* istanbul ignore next */
const defaultRemove = name => Cookies.remove(name);
/* istanbul ignore next */
const defaultDate = () => new Date();
/* istanbul ignore next */
const defaultGet = name => Cookies.get(name);

export const loadUserCookieConsent = (load = defaultLoad) => {
    const consent = load();
    return typeof consent === "undefined" ? undefined : consent.toLowerCase() === "true";
};

export const saveUserCookieConsent = (
    preference: boolean,
    get = defaultGet,
    save = defaultSave,
    remove = defaultRemove,
    notify = defaultNotify,
    getDate = defaultDate,
) => {
    save(preference, { expires: CONSENT_COOKIE_EXPIRY });

    const date = getDate();
    date.setDate(date.getDate() + CONSENT_COOKIE_EXPIRY);
    const domain = `Domain=${process.env.APP_DOMAIN},`;
    let eventData = [`Name=${CONSENT_COOKIE}, ${domain} Expires=${date.toISOString()}, Value=${preference}`];
    if (preference)
        eventData = eventData.concat(additionalCookies.map(c => `Name=${c.name}, ${domain} Value=${get(c.name)}`));
    else additionalCookies.forEach(c => remove(c.name));
    const event = { detail: { type: COOKIE_UPDATE_TYPE, data: eventData.join(";") } };
    notify(event);
};
