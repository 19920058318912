import { AudienceType, IArticleIdentifier } from "../types";

export type QueryResults = {
    totalResultCount: number;
    results: Article[];
    loading: boolean;
    error?: string;
    audienceTypes?: AudienceType[];
    hasNextPage?: boolean;
    nextPage?: () => void;
};

export const ARTICLE_GROUP_NAMES = {
    [AudienceType.Patient]: "Search.PatientArticles",
    [AudienceType.MedicalProfessional]: "Search.ProfessionalArticles",
};

export type Audience = {
    audienceType: AudienceType;
};

export const PatientAudience: Audience = {
    audienceType: AudienceType.Patient,
};

export const MedicalProfessionalAudience: Audience = {
    audienceType: AudienceType.MedicalProfessional,
};

export type Article = IArticleIdentifier & {
    audience: Audience;
    name: string;
};

export type SearchParameters = {
    term: string;
    audienceTypes?: AudienceType[];
    pageIndex?: number;
};
