import { useTranslation } from "@emisgroup/application-intl";
import React from "react";
import { Link } from "react-router-dom";
import parse, { domToReact, HTMLReactParserOptions } from "html-react-parser";
import Error from "../Progress/Error";
import { routedArticleTypes, getArticlePath } from "../utilities/pathUtil";
import TimedSpinner from "../Progress/TimedSpinner";
import { ContentResult } from "./useArticleContentQuery";
import { ArticleBookmark, pushBookmark, tryPopBookmark } from "./articleBookmarks";
import { DisallowedArticleTypes } from "../types";
import ArticleMaintainersFooter from "./ArticleMaintainersFooter";
import moment from "moment";
import ArticleAttribution from "./ArticleAttribution";
const DATE_TIME_MASK = "DD MMMM YYYY";
export type ArticleContentProps = { content: ContentResult };

const error = (): JSX.Element => (
    <Error className="kc-reader__info" title="Reader.ErrorTitle" detail="Reader.ErrorDetail" />
);

const articleTypes = routedArticleTypes().join("|");
const disallowedArticleTypes = DisallowedArticleTypes.join("|");
function replaceLinks(
    options: HTMLReactParserOptions,
    node: any,
    scrollRef: any,
    disabledLinkTitle: string,
    bookmarkLink: (link: string) => void,
    bookmark?: ArticleBookmark,
) {
    const { name, attribs } = node;
    if (name !== "a") return node;

    const disallowedReg = new RegExp(`(${disallowedArticleTypes}):\\/\\/([a-z0-9]+)`, "g");
    const disallowedMatch = disallowedReg.exec(attribs.href);
    if (disallowedMatch !== null) {
        return (
            <span
                data-testid={`disabled-link-${attribs.href}`}
                className="kc-reader__disabled-link"
                title={disabledLinkTitle}
            >
                {domToReact(node.children, options)}
            </span>
        );
    }

    const reg = new RegExp(`(${articleTypes}):\\/\\/([a-z0-9]+)`, "g");

    const match = reg.exec(attribs.href);
    const scrollBookmark = bookmark?.link === attribs.href ? scrollRef : undefined;
    if (match !== null) {
        return (
            <Link
                data-testid={`replaced-link-${attribs.href}`}
                ref={scrollBookmark}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => bookmarkLink(attribs.href)}
                to={getArticlePath(match[1], match[2])}
            >
                {domToReact(node.children, options)}
            </Link>
        );
    }

    // eslint-disable-next-line no-param-reassign
    node.attribs.target = "_blank";
    // eslint-disable-next-line no-param-reassign
    node.attribs.rel = "noreferrer";

    return node;
}
const ArticleContent = (props: ArticleContentProps): JSX.Element => {
    const { t } = useTranslation();
    const { content: contentResult } = props;

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const currentPage = window.location.href;
    const bookmark = tryPopBookmark(currentPage);
    const hasContent = contentResult.content && !(contentResult.loading && contentResult.error);

    React.useEffect(() => {
        if (hasContent && scrollRef?.current?.scrollIntoView) {
            scrollRef.current.scrollIntoView();
        }
    });

    if (contentResult.error) {
        return error();
    }

    if (contentResult.loading)
        return (
            <TimedSpinner
                loading={contentResult.loading}
                large
                className="kc-reader__info"
                text={t("Reader.Loading")}
                error={error}
            />
        );

    const introduction = contentResult.introduction ? parse(contentResult.introduction) : <></>;
    const pushCurrentPageBookmark = link => pushBookmark(currentPage, link);
    const disabledLinkTitle = t("Reader.LinkDisabled");
    const options: HTMLReactParserOptions = {
        replace: (node: any) =>
            replaceLinks(options, node, scrollRef, disabledLinkTitle, pushCurrentPageBookmark, bookmark),
    };
    const content = parse(contentResult.content, options);
    const creator = contentResult.creator;
    const reviewer = contentResult.reviewedBy;
    const review = contentResult.review;
    const attributions = [
        { label: t("Reader.AuthoredBy"), name: creator?.name, url: creator?.url },
        { label: t("Reader.ReviewedBy"), name: reviewer?.name, url: reviewer?.url },
        {
            label: t("Reader.LastEdited"),
            name: review?.lastReviewDate ? moment(review.lastReviewDate).format(DATE_TIME_MASK) : "",
        },
    ];

    const attributionComponents = attributions
        .filter(attributionDetails => attributionDetails.name)
        .map(attributionDetails => (
            <span key={attributionDetails.label}>
                <ArticleAttribution {...attributionDetails} />
            </span>
        ))
        .reduce((prev, curr) => (
            <>
                {prev} <span className="pipeline-styling"> | </span> {curr}
            </>
        ));
    return (
        <article className="kc-reader__body">
            <div ref={bookmark ? undefined : scrollRef}>
                <h1>{contentResult.headline}</h1>
                <div className="authorDetails">{attributionComponents}</div>
                {introduction}
                {content}
                <ArticleMaintainersFooter
                    documentId={contentResult.documentId}
                    version={contentResult.version}
                    contentReview={review}
                />
            </div>
        </article>
    );
};

export default ArticleContent;
