import React from "react";
import { Button, ButtonGroup, Dialog, DialogContent, DialogFooter } from "@emisgroup/ui-kit-react";

type ConfirmationDialogProps = {
    title: string;
    content: string;
    open: boolean;
    yesText: string;
    noText: string;
    onClose: () => void;
    onConfirmed: (confirmed: boolean) => void;
};

const ConfirmationDialog = ({
    title,
    content,
    open,
    yesText,
    noText,
    onClose,
    onConfirmed,
}: ConfirmationDialogProps) => {
    const onConfirm = React.useCallback(() => onConfirmed(true), [onConfirmed]);
    const onReject = React.useCallback(() => onConfirmed(false), [onConfirmed]);
    return (
        <Dialog open={open} title={title} onClose={onClose}>
            <DialogContent>{content}</DialogContent>
            <DialogFooter>
                <ButtonGroup className="confirmation-dialog-button-group">
                    <Button type="button" variant="filled" onClick={onConfirm}>
                        {yesText}
                    </Button>
                    <Button type="button" variant="borderless" onClick={onReject}>
                        {noText}
                    </Button>
                </ButtonGroup>
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmationDialog;
