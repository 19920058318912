import React from "react";

export type TokenContextType = {
    token?: string;
    isTokenValid: boolean;
    refreshToken: () => Promise<void>;
};

const TokenContext = React.createContext<TokenContextType>({
    refreshToken: () => Promise.resolve(),
    isTokenValid: false,
});

export default TokenContext;
