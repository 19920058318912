import React, { useMemo } from "react";
import FeatureContext from "./featuresContext";
import { Feature } from "../components/types";

const isFeatureEnabled = type => {
    const patientMode = process.env.APP_IDP_MODE === "Patient";
    switch (type) {
        case Feature.WriteToRecord:
        case Feature.Advertising:
        case Feature.SendToPatient:
            return !patientMode;
        default:
            return true;
    }
};

const FeatureProvider = ({ children }): JSX.Element => {
    const enableFeature = useMemo(() => ({ isFeatureEnabled }), [isFeatureEnabled]);

    return <FeatureContext.Provider value={enableFeature}>{children}</FeatureContext.Provider>;
};

export default FeatureProvider;
