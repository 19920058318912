import React from "react";
type AttributionInput = {
    label: string;
    name?: string;
    url?: string;
};
const ArticleAttribution = (props: AttributionInput): JSX.Element => {
    const { name, url, label } = props;
    const detailsClass = "authorDetails-content";
    return (
        <>
            <span>{`${label} `}</span>
            {url && (
                <a className={detailsClass} target="_blank" rel="noreferrer" href={url}>
                    {name}
                </a>
            )}
            {!url && <span className={detailsClass}>{name}</span>}
        </>
    );
};

export default ArticleAttribution;
