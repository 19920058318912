import { useTranslation } from "@emisgroup/application-intl";
import { ClearSecondary } from "@emisgroup/icons-react";
import * as React from "react";
import { AudienceType } from "../types";
import { ARTICLE_GROUP_NAMES, SearchParameters } from "./types";

type Filter = {
    name: string;
    onClick: () => void;
};

type FiltersProps = {
    searchParameters: SearchParameters;
    setSearchParameters: (newParameters: SearchParameters) => void;
};

const Filters = (props: FiltersProps): JSX.Element => {
    const { t } = useTranslation();

    const removeAudienceFilter = (audienceType: AudienceType): void =>
        props.setSearchParameters({
            ...props.searchParameters,
            audienceTypes: props.searchParameters.audienceTypes?.filter((a: AudienceType) => a !== audienceType),
        });

    const audienceFilters =
        props.searchParameters.audienceTypes?.map((a: AudienceType) => ({
            name: t(ARTICLE_GROUP_NAMES[a]),
            onClick: (): void => removeAudienceFilter(a),
        })) ?? [];

    return (
        <div className="kc-filters">
            {audienceFilters.map((af: Filter) => (
                <div key={af.name} className="kc-filters__filter">
                    <div className="kc-filters__filter-layout" onClick={af.onClick} role="none">
                        <div data-testid="filter-name">{af.name}</div>
                        <ClearSecondary className="kc-filters__filter-icon" title="clear" color="white" />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Filters;
