import * as React from "react";
import { Route, Routes } from "react-router";
import RoutesData, { IRoutesData } from "./RoutesData";

/**
 * Responsible for rendering the component as per route path
 */
export const MapRoute: React.FC = () => {
    return (
        <>
            <Routes>
                {RoutesData.map((route: IRoutesData, value: number) => (
                    <Route key={route.path} path={route.path} element={<route.component />} />
                ))}
            </Routes>
        </>
    );
};
