import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Subtract } from "utility-types";

export interface IWithNavigation {
    canSelectPrevious: boolean;
    closeArticle: () => void;
    selectPreviousArticle: () => void;
}

const withNavigation = <T extends IWithNavigation>(Component: React.FC<T>): React.FC<Subtract<T, IWithNavigation>> => {
    const WithNavigation = (props: T) => {
        const navigate = useNavigate();
        const location = useLocation();
        const initialLocationKey = React.useRef(location.key);
        const closeArticle = React.useCallback(() => navigate("/"), [navigate]);
        const selectPreviousArticle = React.useCallback(() => navigate(-1), [navigate]);
        const canSelectPrevious = location.key !== initialLocationKey.current;

        return (
            <Component
                {...props}
                canSelectPrevious={canSelectPrevious}
                closeArticle={closeArticle}
                selectPreviousArticle={selectPreviousArticle}
            />
        );
    };

    WithNavigation.displayName = `withNavigation(${Component.name})`;

    return WithNavigation;
};

export default withNavigation;
