import React from "react";
import { IArticleIdentifier } from "../components/types";

export type ArticleContextType = {
    selectedArticle?: IArticleIdentifier;
};

const ArticleContext = React.createContext<ArticleContextType>({});

export default ArticleContext;
