import { IdpType, ISessionContext, SessionItems, TokenState } from "@emisgroup/application-session-management";
import EmisWebEventTypes from "./enum";
import { ICustomEvent, ILoggedInDetail } from "./types";

interface IEventHandlerProps {
    sessionContext: ISessionContext;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
}

export const AddPatientIdpEventHandlers = (props: IEventHandlerProps): void => {
    const { sessionContext, setIsAuthenticated } = props;

    const handlePatientIdpLoggedIn = (e: any) => {
        const { detail } = e as ICustomEvent;
        if (detail && Object.keys(detail).includes("access_token")) {
            sessionContext.UpdateAccessToken({
                idp: IdpType.Patient,
                value: (detail as ILoggedInDetail).access_token,
            });
            setIsAuthenticated(sessionContext.accessToken.state === TokenState.Valid);
        } else {
            throw new Error("Invalid logged in format");
        }
    };
    document.addEventListener("PatientIdp_LoggedIn", handlePatientIdpLoggedIn);
};

export const RemovePatientIdpEventHandlers = (): void => {
    document.removeEventListener("PatientIdp_LoggedIn", () => null);
};

export const AddEmisWebEventHandlers = (props: IEventHandlerProps): void => {
    const { sessionContext, setIsAuthenticated } = props;

    const handleEmisWebPatientClear = () => {
        sessionContext.RemoveItem(SessionItems.Patient);
    };

    const handleEmisWebPatientChange = (e: any) => {
        const { detail } = e as ICustomEvent;
        const patient = detail;
        if (patient && Object.keys(patient).includes("PatientGuid")) {
            sessionContext.SetItem(SessionItems.Patient, JSON.stringify(detail));
        } else {
            throw new Error("Invalid patient change format");
        }
    };

    const handleEmisWebLoggedIn = (e: any) => {
        const { detail } = e as ICustomEvent;
        if (detail && Object.keys(detail).includes("access_token")) {
            sessionContext.UpdateAccessToken({
                idp: IdpType.Emis,
                value: (detail as ILoggedInDetail).access_token,
            });
            setIsAuthenticated(sessionContext.accessToken.state === TokenState.Valid);
        } else {
            throw new Error("Invalid logged in format");
        }
    };

    document.addEventListener(EmisWebEventTypes.PATIENT_CHANGE_EVENT, handleEmisWebPatientChange);
    document.addEventListener(EmisWebEventTypes.PATIENT_CLEAR_EVENT, handleEmisWebPatientClear);
    document.addEventListener(EmisWebEventTypes.LOGGED_IN_EVENT, handleEmisWebLoggedIn);
};

export const RemoveEmisWebEventHandlers = (): void => {
    document.removeEventListener(EmisWebEventTypes.PATIENT_CHANGE_EVENT, () => null);
    document.removeEventListener(EmisWebEventTypes.PATIENT_CLEAR_EVENT, () => null);
    document.removeEventListener(EmisWebEventTypes.LOGGED_IN_EVENT, () => null);
};
