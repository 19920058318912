import * as React from "react";
import { IntlProvider as AppIntlProvider } from "@emisgroup/application-intl";
import enGBMessages from "./locales/en-GB/translation.json";
import { enGBMessages as commsEmGBMessages } from "@emisgroup/clint-comms-ui/lib/locales/en-GB/translation";
import { IChildrenProp } from "./Types";

export const applicationNamespace = "App";

export const resources = {
    "en-GB": {
        [applicationNamespace]: { ...enGBMessages, ...commsEmGBMessages },
    },
};

type Props = IChildrenProp;

/**
 * Responsible for rendering the IntlProvider component
 */
const IntlProvider: React.FC<Props> = (props: Props) => {
    return (
        <AppIntlProvider resources={resources} defaultNS={applicationNamespace}>
            {props.children}
        </AppIntlProvider>
    );
};

export { IntlProvider };
