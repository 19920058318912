import { ApolloClient, InMemoryCache, Operation, NextLink, ApolloLink, HttpLink } from "@apollo/client";

const httpLink = new HttpLink({
    uri: process.env.APP_ARTICLE_DATA_URL,
});

const authMiddleware = (authToken: string | undefined): any =>
    new ApolloLink((operation: Operation, forward: NextLink) => {
        if (authToken) {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${authToken}`,
                },
            });
        }

        return forward(operation);
    });

const cache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
        Query: {
            fields: {
                search: {
                    keyArgs: ["term", "audienceType", "type"],
                    // eslint-disable-next-line @typescript-eslint/default-param-last
                    merge(existing = { results: [] }, incoming) {
                        return { ...incoming, results: existing.results.concat(incoming.results) };
                    },
                },
            },
        },
    },
});

const useApolloClient = (accessToken?: string): ApolloClient<any> => {
    return new ApolloClient({
        link: authMiddleware(accessToken).concat(httpLink),
        cache,
    });
};

export default useApolloClient;
