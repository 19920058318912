import { createContext } from "react";
import { ArticleType, AudienceType } from "./components/types";
import { Article } from "./components/Search/types";
import { getArticleTypeFromPath } from "./components/utilities/pathUtil";
import packageJson from "../package.json";

type SearchEvent = { term: string; audienceType: AudienceType; timeMs: number } & { results: Article[] };
type ArticleEvent = { path: string; type?: ArticleType; currentCookieConsent?: boolean };
export type ExceptionTelemetry = { exception: Error; properties?: { [key: string]: any } };

type TelemetryContextType = {
    trackSearch: (event: SearchEvent) => void;
    trackCopy: (event: ArticleEvent) => void;
    trackPrint: (event: ArticleEvent) => void;
    trackSelect: (event: ArticleEvent) => void;
    trackSend: (event: ArticleEvent) => void;
    trackMissingOrgName: (event: any) => void;
    trackException: (event: ExceptionTelemetry) => void;
};

export const TelemetryContext = createContext<TelemetryContextType>({
    trackSearch: () => ({}),
    trackCopy: () => ({}),
    trackPrint: () => ({}),
    trackSelect: () => ({}),
    trackSend: () => ({}),
    trackMissingOrgName: () => ({}),
    trackException: () => ({}),
});

const SEARCH_EVENT_NAME = "Search";
const COPY_EVENT_NAME = "CopyLink";
const PRINT_EVENT_NAME = "PrintArticle";
const SELECT_EVENT_NAME = "SelectArticle";
const SEND_EVENT_NAME = "SendArticle";
const MISSING_ORG_NAME = "MissingOrgName";

const DEFAULT_SEARCH_TRACK = { term: "", audienceType: AudienceType.Patient, timeMs: 0, results: [] as Article[] };
const DEFAULT_ARTICLE_TRACK = { path: "" };

export function createValue(
    useTrackEvent: <DataType>(eventName: string, eventData: DataType) => React.Dispatch<DataType>,
    trackException: (exceptionTelemetry: ExceptionTelemetry) => void,
): TelemetryContextType {
    return {
        trackSearch: useTrackEvent(SEARCH_EVENT_NAME, DEFAULT_SEARCH_TRACK),
        trackSelect: useTrackEvent(SELECT_EVENT_NAME, DEFAULT_ARTICLE_TRACK),
        trackCopy: useTrackEvent(COPY_EVENT_NAME, DEFAULT_ARTICLE_TRACK),
        trackPrint: useTrackEvent(PRINT_EVENT_NAME, DEFAULT_ARTICLE_TRACK),
        trackSend: useTrackEvent(SEND_EVENT_NAME, DEFAULT_ARTICLE_TRACK),
        trackMissingOrgName: useTrackEvent(MISSING_ORG_NAME, {}),
        trackException,
    };
}

function getArticleType(item) {
    const { data, baseData } = item;
    let path = "";
    if (data?.path) {
        path = data?.path;
    } else if (baseData?.uri) {
        try {
            path = new URL(baseData?.uri).pathname;
        } catch {
            path = baseData?.uri;
        }
    }

    if (path) {
        const [typeString] = path.replace("/", "").split(":");
        return getArticleTypeFromPath(typeString);
    }

    return null;
}

export function augmentData(item) {
    if (!item.data) {
        return;
    }

    item.data.version = packageJson.version;

    const articleType = getArticleType(item);
    if (articleType) {
        item.data.articleType = articleType;
    }

    item.data.idpMode = process.env.APP_IDP_MODE;
}
