import { ISessionContext, SessionItems } from "@emisgroup/application-session-management";

export const PATIENT_SESSION_ITEM = SessionItems.Patient;

const getPatientProperty = (propertyName: string) => (sessionContext: ISessionContext) => {
    try {
        const patient = JSON.parse(sessionContext.GetItem(PATIENT_SESSION_ITEM) as string);
        return patient[propertyName] ?? "";
    } catch {
        return "";
    }
};

export const getPatientIdentifier = getPatientProperty("PatientGuid");
export const getPatientPreferredName = getPatientProperty("PatientPreferredName");
export const getPatientForenames = getPatientProperty("PatientForenames");
export const getPatientSurname = getPatientProperty("PatientSurname");
export const getPatientTitle = getPatientProperty("PatientTitle");
