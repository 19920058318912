import React from "react";
import { NotificationType } from "@emisgroup/ui-kit-react";
import TimedNotification from "./TimedNotification";

export type NotificationObj = {
    text: string;
    type: NotificationType;
};

type NotificationsProps = {
    notifications: NotificationObj[];
    onCloseNotification: (notification: NotificationObj) => void;
};

const Notifications = ({ notifications, onCloseNotification }: NotificationsProps) => {
    return (
        <div className="kc-notification-container">
            {notifications.map(n => (
                <TimedNotification
                    key={`${n.type}|${n.text}`}
                    type={n.type}
                    text={n.text}
                    milliseconds={10000}
                    // eslint-disable-next-line react/jsx-no-bind
                    onTimeout={() => onCloseNotification(n)}
                />
            ))}
        </div>
    );
};

export default Notifications;
