import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { ContentReview } from "./useArticleContentQuery";

const ArticleMaintainersFooterValue = (props: { label: string; value?: string }) => {
    const { label, value } = props;
    return (
        <div className="kc-reader__maintainers-table-cell">
            <div className="kc-reader__maintainers-label">{label && `${label}:`}</div>
            <div className="kc-reader__maintainers-value">{value}</div>
        </div>
    );
};

const ArticleMaintainersFooter = (props: { documentId?: string; version?: string; contentReview?: ContentReview }) => {
    const { t } = useTranslation();
    const { documentId, version, contentReview } = props;
    const { lastReviewDate, nextReviewDate } = contentReview ?? {};
    const documentDisplay = documentId && version ? `${documentId} (v${version})` : undefined;
    return (
        <div className="kc-reader__maintainers">
            <div className="kc-reader__maintainers-table">
                <ArticleMaintainersFooterValue label={t("Reader.DocumentId")} value={documentDisplay} />
                <ArticleMaintainersFooterValue label={t("Reader.LastReview")} value={lastReviewDate} />
                <ArticleMaintainersFooterValue label={t("Reader.NextReview")} value={nextReviewDate} />
            </div>
        </div>
    );
};

export default ArticleMaintainersFooter;
